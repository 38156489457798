/*
 * @Author: your name
 * @Date: 2020-05-19 11:52:48
 * @LastEditTime: 2020-05-26 14:48:41
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \vue\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const routes = [{
        path: '/login',
        name: 'login',
        component: () =>
            import ('../views/login.vue')
    },
    {
        path: '/',
        name: 'index',
        component: () =>
            import ('../views/index.vue')
    },
    {
        path: '/info',
        name: 'info',
        component: () =>
            import ('../views/info.vue')
    },
    {
        path: '/infodetails',
        name: 'infodetails',
        component: () =>
            import ('../views/infodetails.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import ('../views/about.vue')
    },
    {
        path: '/case',
        name: 'case',
        component: () =>
            import ('../views/case.vue')
    },

    {
        path: '/indexsearch',
        name: 'indexsearch',
        component: () =>
            import ('../views/indexsearch.vue')
    },
    {
        path: '/aboutdetails',
        name: 'aboutdetails',
        component: () =>
            import ('../views/aboutdetails.vue')
    },
    {
        path: '/abtoaboit',
        name: 'abtoaboit',
        component: () =>
            import ('../views/abtoaboit.vue')
    },
    {
        path: '/caseyushutdetails',
        name: 'caseyushutdetails',
        component: () =>
            import ('../views/caseyushutdetails.vue')
    },
    {
        path: '/Latestfeatures',
        name: 'Latestfeatures',
        component: () =>
            import ('../views/Latestfeatures.vue')
    },
    {
        path: '/LatestActivities',
        name: 'LatestActivities',
        component: () =>
            import ('../views/LatestActivities.vue')
    },
    {
        path: '/ReadingRules',
        name: 'ReadingRules',
        component: () =>
            import ('../views/ReadingRules.vue')
    },
    {
        path: '/helpzhxin',
        name: 'helpzhxin',
        component: () =>
            import ('../views/helpzhxin.vue')
    },
    {
        path: '/guizedetail',
        name: 'guizedetail',
        component: () =>
            import ('../views/guizedetail.vue')
    }

]
const router = new VueRouter({
    //history
    //hash
    mode: 'hash',
    // base: process.env.BASE_URL,
    base: '/',
    routes
})
export default router