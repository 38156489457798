/*
 * @Author: your name
 * @Date: 2020-05-26 14:47:04
 * @LastEditTime: 2020-05-27 16:21:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \新建文件夹 (2)\src\main.js
 */

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import "babel-polyfill"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
Vue.use(VueAwesomeSwiper, /* { default options with global component } */ )
Vue.config.ignoredElements = ['wx-open-launch-weapp']
Vue.use(ElementUI);
Vue.prototype.$http = axios;
Vue.config.productionTip = false

Vue.prototype.baseurl = "https://schoolapi.xiaobang.cloud";
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')