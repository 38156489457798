<template>
  <div class="foot" v-if="info">
    <div class="content">
      <div class="tbom">
        <div class="tobm-list-img"><img :src="totlist.bottom_left.value"/></div>
        <div class="tobm-massge-list">
          <div class="tobm-biaoti-list" >
              <p style="cursor: pointer;margin-right: 10px;" v-for="(item,index) in shouyliat" :key="index" @click="topage(item)">{{item.title}}<span v-if="index != shouyliat.length - 1" style="margin-left: 10px;">|</span></p>
              <!-- <p style="margin-right: 10px;">网站首页<span style="margin-left: 10px;">|</span></p>
              <p style="margin-right: 10px;">网站首页<span style="margin-left: 10px;">|</span></p>
              <p>网站首页</p> -->
          </div>
          <div>联系人：{{totlist.contacts.value}}</div>
          <div>邮箱：{{totlist.email.value}}</div>
          <div>客服电话：{{totlist.landline.value}}</div>
          <div>备案号：{{totlist.beian.value}}</div>
        </div>
        <div>
          <div class="tom-erweima"><img :src="totlist.bottom_right.value"/></div>
          <!-- <div class="weixin-yanjiu">{{totlist.bottom_right.title}}</div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:"foot",
    data () {
      return {
        info:true,
        phone:'',
        hide:false,
        totlist:{},
        shouyliat:[]
      }
    },
    watch:{
      $route:{
        handler(val,oldval){
          if(val.name == 'login'){
            this.info = false
          }else{
            this.info = true
            this.indexlist()
            this.daohanglist()
          }
        },
        deep: true
      }
    },
    created(){
      
    },
    mounted () {
     
    },
    methods: {
      // 
      topage(data){
        if(data.id==1){
          this.$router.push('/')
        }
        if(data.id==2){
          this.$router.push('/case')
        }
        if(data.id==3){
          this.$router.push('/about')
        }
        if(data.id==4){
          this.$router.push('/info')
        }
      },
      jump(e){
        this.$router.push('/info?phone=' + this.phone)
      },
      indexlist(){
        let that = this
        that.$http({
          url: that.baseurl+"/getSysBottomConfig",
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.data);
                  console.log(res,'##########44')
                 that.totlist= res.data.data;
              }else{
                  // that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      daohanglist(){
        let that = this
        that.$http({
          url: that.baseurl+"/getApiNav",
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==400){
                  // that.$message.success(res.data.data);
                  console.log(res,'##########44')
                 that.shouyliat= res.data.msg
              }else{
                  // that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
    },
  }

</script>
<style scoped>
  .foot{
    width: 100%;
    /* background: url('../assets/bim.png') no-repeat;
    background-size: 100% 100%; */
    background-color: #011532;
    height: 298px;
  }
  .content{
    position: relative;
    width: 80%;
     max-width: 1520px;
    margin: 0 auto;
  }
  .tbom{
    /* position: absolute;
    left: 0;
    top: 200px; */
    width: 100%;
    /* border-bottom: solid 1px #ffffff99; */
    padding-bottom: 30px;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    color: #FFFFFF;
    font-size: 14px;
    padding-top:40px;
    
  }
  .tobm-list-img{
      
  }
  .tobm-list-img img{
     width: 286px;
      height: 38px;
  }
 
 .tobm-biaoti-list{
      display: flex;
 }
 .tom-erweima img{
      width: 154px;
      height: 154px;
      border-radius: 8px;
 }
 .tobm-massge-list{
  line-height: 45px;
 }
 .weixin-yanjiu{
  margin-top: 10px;
 }
  .ptitle{
    text-align: center;
    font-size: 2vw;
    font-family: PingFang SC;
    line-height: 100px;
    color: #FFFFFF;
    letter-spacing: 10px;
  }

  .inp{
    width: 650px;
    margin: 0 auto;
    display: flex;
    height: 54px;
    background: #fff;
    border-radius: 33px;
    justify-content: space-between;
    overflow: hidden;
  }
  .inp>input{
    padding-left: 40px;
    width: 380px;
    border: none;
    outline: none;
  }
  .inp>div{
    width: 210px;
    height: 48px;
    text-align:center;
    line-height: 48px;
    background: #015CE9;
    border-radius: 33px;
    color: #fff;
    margin-top: 3px;
    margin-right: 3px;
    cursor: pointer;
  }

  .bombom{
    margin-top: 50px;
    padding-top: 20px;
    padding-bottom: 50px;
    border-top: solid 1px #ffffff99;
    display: flex;
    font-size: 14px;
    line-height: 24px;
    justify-content: space-between;
    color: #DCDCDC;
  }
  .bombom>div>img{
    width: 17.78px;
    height: 24px;
    vertical-align: middle;
  }
  .bombom>div>span{
    vertical-align: middle;
  }
  .hidehead{
    display: none;
  }
  
</style>
