<template>
  <div id="app">
    <pagehead @toptop='toptop' @routerche='routerche'></pagehead>
    <router-view ref="toptop"/>
    <pagefoot></pagefoot>
  </div>
</template>
<script>
  import $ from 'jquery'
  import pagehead from '../src/components/head.vue'
  import pagefoot from '../src/components/foot.vue'
  export default {
    components: {
      pagehead,
      pagefoot
    },
    name:"app",
    data () {
      return {
        shoew:false,
        list:'',
        hide:false,
      }
    },
    // created(){
    //   if(this.isMobile() || $(window).width()<= 750){
    //     window.location.href = 'https://wap.gongmiaocha.com/';
    //   }
    // },
    created(){
      let that = this
      let gqlogin = true
      // console.log(this.$router,'路由')
      // console.log(this.$router.options.routes[0].name,'精确地址')
      that.$http.interceptors.response.use(
        response => {
             if(response?.['data']?.code == 401){
              gqlogin&&that.$router.options.routes[0].name!='login' ? that.$message.error('请重新登陆') : null
              gqlogin = false
              that.$router.push('/login')
              console.log()
            }else{
              gqlogin = true
              return response;
            }
          
         
        }
      );
    },
    watch(){
      
    },
    mounted () {
      document.title = '邦伲德 - 小邦学习中心'
      console.log(this.$router.options.routes,'######')
    },
    methods: {

     
      isMobile() {
        var userAgentInfo = navigator.userAgent;

        var mobileAgents = [ "Android", "iPhone", "SymbianOS", "Windows Phone", "iPad","iPod"];

        var mobile_flag = false;

        //根据userAgent判断是否是手机
        for (var v = 0; v < mobileAgents.length; v++) {
            if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
                mobile_flag = true;
                break;
            }
        }

        var screen_width = window.screen.width;
        var screen_height = window.screen.height;    

        //根据屏幕分辨率判断是否是手机
        if(screen_width < 500 && screen_height < 800){
            mobile_flag = true;
        }

        return mobile_flag;
    },
      toptop(){
        this.$refs.toptop.toptop()
      },
      routerche(){
        this.$refs.toptop.routerche()
      }

    },
  }

</script>
<style lang="less">
*{
  margin: 0;
  padding: 0;
}
.home{
  // max-width: 600px;
}
#app {
  // position: relative;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  // color: #2c3e50;
  // background: #666;
  // height: 300px;
}

#nav {
  // padding: 30px;

  // a {
  //   font-weight: bold;
  //   color: #2c3e50;

  //   &.router-link-exact-active {
  //     color: #42b983;
  //   }
  // }
}

.el-loading-mask{
  z-index: 99999 !important;
}
</style>
