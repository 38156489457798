<template>
  <div class="head-con" :class="hide?'hidehead':''">
    <div class="head">
      <div class="head-lid">
        <img class="image-loge" v-if="imglogo.logo.value!=''" :src="imglogo.logo.value" alt="">
        <div class="center-hzong">
          <div class="daohang-list">
            <div class="seletype" v-for="(item,index) in totlist" :key="index" @click="jomp(item.id)" :class="tyotid==item.id?'active':''">{{item.title}}</div>
            <!-- <div class="seletype" @click="jomp('/')" :class="index == 0?'active':''">首页</div>
            <div class="seletype" @click="jomp('/case')" :class="index == 1?'active':''">规则中心</div>
            <div class="seletype" @click="jomp('/about')" :class="index == 2?'active':''">课程中心</div>
            <div class="seletype" @click="jomp('/info')" :class="index == 3?'active':''">帮助中心</div> -->
          </div>
        </div>
      </div>
      
      <div class="icon-list">
        <div class="sousuo-imm" @click="sousuolist()">
          <img src="../assets/image/sousuing.png" alt="">
        </div>
        <div class="xiera">
          <el-popover
            placement="top"
            width="60"
            trigger="hover">
            <div style="text-align: center;" @click="tologin()">退出登录</div>
            <div  class="title-img" slot="reference">
              <img src="../assets/image/dengu_title.png"/>
            </div>
          </el-popover>
        </div>
          <!-- <el-popover
            placement="top"
            width="400"
            trigger="click">
            <div>退出登录</div>
              <div class="title-img"><img  @click="tologin()" src="../assets/image/dengu_title.png"/></div>
          </el-popover> -->
        
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name:"head",
    data () {
      return {
        tyotid:1,
        hide:false,
        totlist:[],
        imglogo:{}
      }
    },
    watch:{
      $route:{
        handler(val,oldval){
          // console.log(val,'%%%%%')
          if(val.name == 'info'){
            this.tyotid = 4
          }

          if(val.name == 'about'){
            this.tyotid = 3
          }

          if(val.name == 'case'){
            this.tyotid = 2
          }

          if(val.name == 'index'){
            this.tyotid = 1
          }

          if(val.name == 'login'){
            this.hide = true
          }else{
            this.hide = false
            this.indexlist()
            this.getljgfdi()
          }
        },
        deep: true
      }
    },
    created(){
      
    },
    mounted () {
      
    },
    methods: {
      jomp(id){
        // console.log(id,'%%%%%')
        // this.tyotid = id
        if(id==1){
          this.$router.push('/')
        }
        if(id==2){
          this.$router.push('/case')
        }
        if(id==3){
          this.$router.push('/about')
        }
        if(id==4){
          this.$router.push('/info')
        }
      },
      
      //搜索列表
      indexlist(){
        let that = this
        that.$http({
          url: that.baseurl + "/getApiNav",
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==400){
                  // that.$message.success(res.data.data);
                 that.totlist= res.data.msg
              }else{
                  // that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      getljgfdi(){
        let that = this
        that.$http({
          url: that.baseurl+"/getSysBottomConfig",
          method: "get",
          headers:{
           "web-token":localStorage.getItem('auto_token')
          },
          data:{}
        })
          .then(res => {
              if(res.data.code==200){
                  // that.$message.success(res.data.data);
                 
                 that.imglogo= res.data.data;
                  console.log(that.imglogo.logo.value,'##########44')
              }else{
                  // that.$message.error(res.data.msg);
                  // that.$router.push('/')
              }
              
          })
          .catch(function(error) {
              //  that.$message.error(res.data.message);
        });
      },
      tologin(){
        localStorage.removeItem('auto_token');//只需要在退出成功后清除了就可以
        setTimeout((_)=>{
           this.$router.push('/login')
        },1000)
       
        // window.open(`https://pro.gongmiaocha.com/login`); 
        // window.location.href = 'https://pro.gongmiaocha.com/login'
      },
      // 搜索页面
      sousuolist(){
        this.$router.push('/indexsearch')
      }
    },
  }

</script>
<style scoped>
  .head-con{
    width: 100%;
    height: 64px;
    background: hsl(225, 91%, 56%);
    min-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    z-index: 9;
  }
  .head{
    max-width: 1520px;
    width: 80%;
    display: flex;
    justify-content:space-between;
    height: 64px;
  }
  .head>div:nth-child(1){
    /* vertical-align: middle;
    padding: 15px 0; */
  }
  .head>div:nth-child(1) img{
    width: 224px;
    height: 31px;
    vertical-align: middle;
    cursor: pointer;
  }
  .head-lid{
    display:flex;
     align-items: center;
  }
  
  .image-loge{
    width: 224px;
    height: 31px;
    vertical-align: middle;
    cursor: pointer;
    margin-right:64px;
  }
  .daohang-list{
    display: flex;
    
  }
  .sousuo-imm{
    margin-right: 15px;
  }
  .sousuo-imm img{
    width: 18px;
    height: 18px;
  }
  .icon-list{
    display: flex;
    padding: 20px 0;
    align-items: center;
  }
  .center-hzong{
    padding: 15px 0;
  }
  .seletype{
    outline: none;
    text-align: center;
    /* line-height: 52px; */
    padding-bottom: 10px;
    font-size: 19px;
    font-family: PingFang SC;
    color: #FFFFFF;
    margin-right: 60px;
    position: relative;
    cursor: pointer;
  }
  .seletype i{
    transform: scale(.8);
  }
  .seletype::after{
    content: '';
    width: 0px;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transition: all .2s;
    transform: translateX(-50%);
  }
  .seletype:hover:after{
    width: 40px;
    /* transform: translateX(-50%); */
  }
  .active::before{
    content: '';
    width:40px;
    height: 2px;
    background: #fff;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .title-img{
    width: 40px;
    height: 40px;
    border-radius: 5px;
  }
  .title-img img{
    width: 100%;
    height: 100%;
  }
  .modesmol{
    width: 180px;
  }
  .modesmol>div{
    width: 150px;
    text-align: center;
    line-height: 35px;
    cursor: pointer;
  }
  .modesmol>div:hover{
    color: #015CE9;
  }
  .activesub{
    color: #015CE9;
  }


  .colorf{
    color: #1F1F1F !important;
  }
  .borderf{
    border-color: #015CE9 !important;
  }
  .borderf span{
    color: #015CE9 !important;
  }
  .btnbg{
    background: #015CE9 !important;
    border-color:#015CE9 !important;
  }
  .titlebg .active::before{
    background: #015CE9 !important;
  }
  .titlebg .seletype::after{
    background: #015CE9 !important;
  }
  .borbtm{
    border-bottom: solid 1px #1F1F1F2B;
  }



  .hidehead{
    display: none;
  }
</style>
<style>
.xiera .el-popover el-popper {
  min-width: 100px !important;
}
</style>
